import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from 'src/app/services/logger.service';
import { Tenant, TenantsService } from 'src/app/services/tenants.service';

@Component({
  selector: 'app-tenant-admin-edit',
  templateUrl: './tenant-admin-edit.component.html',
  styleUrl: './tenant-admin-edit.component.scss',
})
export class TenantAdminEditComponent {
  tenant?: Tenant;
  tenantFetchError?: string;
  fetchingTenant = true;

  constructor(
    private tenantsService: TenantsService,
    private route: ActivatedRoute,
    private loggerService: LoggerService
  ) {
    this.fetchingTenant = true;
    const tenantId = this.route.snapshot.paramMap.get('tenantId');
    if (tenantId === null || tenantId === '') {
      this.loggerService.error(
        new Error('tenant id not provided in route params')
      );
      this.tenantFetchError = 'Tenant ID not provided in route params';
      this.fetchingTenant = false;
      return;
    }

    this.tenantsService.getTenantById(tenantId).subscribe({
      next: (res) => {
        this.fetchingTenant = false;
        this.loggerService.log('res: ', res);
        if (res.tenant) {
          this.tenant = res.tenant;
        } else {
          this.loggerService.error(new Error('No tenant returned from server'));
          this.tenantFetchError = 'No tenant returned from server';
        }
      },
      error: (err) => {
        this.fetchingTenant = false;
        this.loggerService.error(err);
        this.tenantFetchError = err;
      },
    });
  }
}
