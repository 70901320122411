import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  InvitationsService,
  UserInvitation,
  UserInvitationRequest,
} from 'src/app/services/invitations.service';
import {
  InvoiceDetails,
  InvoicesService,
} from 'src/app/services/invoices.service';
import {
  DesktopLicenseWithProduct,
  LicensesService,
} from 'src/app/services/licenses.service';
import { Role, RolesService } from 'src/app/services/roles.service';
import { Tenant, TenantsService } from 'src/app/services/tenants.service';
import {
  UserWithPermissions,
  UsersService,
} from 'src/app/services/users.service';
import { LoggerService } from 'src/app/services/logger.service'; // Import LoggerService
import {
  Franchise,
  FranchisesService,
} from 'src/app/services/franchises.service';
import { AuthService } from 'src/app/services/auth.service';

type LicenseGroups = {
  active: DesktopLicenseWithProduct[];
  expired: DesktopLicenseWithProduct[];
  pending: DesktopLicenseWithProduct[];
};

@Component({
  selector: 'app-tenant-details',
  templateUrl: './tenant-details.component.html',
  styleUrls: ['./tenant-details.component.scss'],
})
export class TenantDetailsComponent {
  @ViewChild('addLicenseDialog', { static: true })
  addLicenseDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('createInvoiceDialog', { static: true })
  createInvoiceDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('inviteUserModal') inviteUserModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('productSearchInput')
  productSearchInput!: ElementRef<HTMLInputElement>;

  tenant?: Tenant;
  franchise?: Franchise;
  desktopLicenses?: LicenseGroups;
  desktopInvoices?: {
    pending: InvoiceDetails[];
    complete: InvoiceDetails[];
    expired: InvoiceDetails[];
  };
  users?: UserWithPermissions[];
  invitations?: {
    pending: UserInvitation[];
    accepted: UserInvitation[];
    expired: UserInvitation[];
  };

  loadingUsers = false;
  loadingInvitations = false;
  loadingTenant = false;
  loadingFranchise = false;
  loadingLicenses = false;
  loadingInvoices = false;

  error = '';
  errorFetchingTenant = '';
  errorFetchingFranchise = '';
  errorFetchingLicenses = '';
  errorFetchingInvoices = '';
  errorFetchingUsers = '';
  errorFetchingInvitations = '';
  errorFetchingRoles = '';

  roles?: Role[];
  selectedRoleId = '';
  invitationEmail = '';
  successSendingInvitation = false;
  sendingInvitation = false;
  errorSendingInvitation = '';

  invoiceCreatedSuccessfully = false;

  constructor(
    private authService: AuthService,
    private franchisesService: FranchisesService,
    private invitationsService: InvitationsService,
    private invoicesService: InvoicesService,
    private licensesService: LicensesService,
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private tenantsService: TenantsService,
    private usersService: UsersService,
    private loggerService: LoggerService
  ) {}

  ngOnInit(): void {
    this.loggerService.log('ProductDetailsComponent ngOnInit');
    const tenantId = this.route.snapshot.paramMap.get('id');

    if (tenantId === null || tenantId === '') {
      this.loggerService.error(
        new Error('tenant id not provided in route params')
      );
      this.error = 'Tenant ID not provided in route params';
      return;
    }

    this.getTenantDetails(tenantId);
    this.getLicensesForTenant(tenantId);
    this.getInvoicesForTenant(tenantId);
    this.getUsersForTenant(tenantId);
    this.getUserInvitationsForTenant(tenantId);
    this.getRoles();
  }

  getTenantDetails(tenantId: string) {
    this.loadingTenant = true;
    this.tenantsService.getTenantById(tenantId).subscribe({
      next: (tenant) => {
        this.tenant = tenant.tenant;
        if (tenant.tenant.franchise_id) {
          this.getFranchiseDetails(tenant.tenant.franchise_id);
        } else {
          this.loadingTenant = false;
        }
      },
      error: (err) => {
        this.loggerService.error(err);
        this.loadingTenant = false;
        this.errorFetchingTenant = err;
      },
    });
  }

  getFranchiseDetails(franchiseId: string) {
    this.loadingFranchise = true;
    this.franchisesService.getFranchiseById(franchiseId).subscribe({
      next: (franchise) => {
        this.loadingFranchise = false;
        this.loadingTenant = false;
        this.franchise = franchise.franchise;
      },
      error: (err) => {
        this.loadingFranchise = false;
        this.loadingTenant = false;
        this.loggerService.error(err);
        this.errorFetchingFranchise = err;
      },
    });
  }

  getLicensesForTenant(tenantId: string) {
    this.loadingLicenses = true;
    this.licensesService.getLicensesForTenant(tenantId).subscribe({
      next: (licenses) => {
        this.loadingLicenses = false;
        this.loggerService.log('licenses: ', licenses);
        this.desktopLicenses = this.sortLicenses(licenses.desktop_licenses);
      },
      error: (err) => {
        this.loggerService.error(err);
        this.loadingLicenses = false;
        this.errorFetchingLicenses = err;
      },
    });
  }

  sortLicenses(licenseGroups: LicenseGroups) {
    for (const group in licenseGroups) {
      const licenses = licenseGroups[group as keyof LicenseGroups];

      // get parent licenses first
      const parentLicenses = licenses.filter(
        (l) => !l.parent_desktop_license_id
      );
      parentLicenses.sort((a, b) => {
        if (a.product.title < b.product.title) return -1;
        if (a.product.title > b.product.title) return 1;
        return 0;
      });

      // insert child licenses after each parent licenses
      const workingLicenses: DesktopLicenseWithProduct[] = [];

      for (const parentLicense of parentLicenses) {
        workingLicenses.push(parentLicense);
        const childLicenses = licenses.filter(
          (l) => l.parent_desktop_license_id === parentLicense.id
        );
        for (const childLicense of childLicenses) {
          workingLicenses.push(childLicense);
          const grandChildLicenses = licenses.filter(
            (l) => l.parent_desktop_license_id === childLicense.id
          );
          for (const grandChildLicense of grandChildLicenses) {
            workingLicenses.push(grandChildLicense);
          }
        }
      }

      for (const license of licenses) {
        if (!workingLicenses.find((l) => l.id === license.id)) {
          workingLicenses.push(license);
        }
      }

      licenseGroups[group as keyof LicenseGroups] = workingLicenses;
    }

    return licenseGroups;
  }

  getInvoicesForTenant(tenantId: string) {
    this.loadingInvoices = true;
    this.invoicesService.getInvoicesForTenant(tenantId).subscribe({
      next: (invoices) => {
        this.loggerService.log('invoices: ', invoices);
        this.loadingInvoices = false;
        this.desktopInvoices = invoices.desktop_invoices;
      },
      error: (err) => {
        this.loggerService.error(err);
        this.loadingInvoices = false;
        this.errorFetchingInvoices = err;
      },
    });
  }

  getUsersForTenant(tenantId: string) {
    this.loadingUsers = true;
    this.usersService.getUsersForTenant(tenantId).subscribe({
      next: (users) => {
        this.loadingUsers = false;
        this.users = users.users;
      },
      error: (err) => {
        this.loggerService.error(err);
        this.loadingUsers = false;
        this.errorFetchingUsers = err;
      },
    });
  }

  getUserInvitationsForTenant(tenantId: string) {
    this.loadingInvitations = true;
    this.invitationsService.getInvitationsForTenant(tenantId).subscribe({
      next: (res) => {
        this.loadingInvitations = false;
        this.invitations = res.user_invitations;
      },
      error: (err) => {
        this.loggerService.error(err);
        this.loadingInvitations = false;
        this.errorFetchingInvitations = err;
      },
    });
  }

  getRoles() {
    this.rolesService.getRolesWithAccess().subscribe({
      next: (res) => {
        this.roles = res.roles;
      },
      error: (err) => {
        this.errorFetchingRoles = err;
      },
    });
  }

  roleList(user: UserWithPermissions) {
    return user.permissions.map((p) => p.role.display_name).join(', ');
  }

  showAddLicenseTable() {
    this.addLicenseDialog?.nativeElement.showModal();
  }

  handleLicenseAdded(license: DesktopLicenseWithProduct) {
    this.loggerService.log('handleLicenseAdded: ', license);

    if (this.desktopLicenses) {
      this.desktopLicenses.pending.push(license);
      // assign new object to force change detection
      this.desktopLicenses = Object.assign({}, this.desktopLicenses);
    } else {
      this.desktopLicenses = {
        active: [],
        expired: [],
        pending: [license],
      };
    }

    this.sortLicenses(this.desktopLicenses);

    this.addLicenseDialog?.nativeElement.close();
  }

  showCreateInvoiceModal() {
    this.createInvoiceDialog.nativeElement.showModal();
  }

  handleInvoiceCreated(invoice: InvoiceDetails) {
    this.loggerService.log('invoice created: ', invoice);
    this.invoiceCreatedSuccessfully = true;

    if (this.desktopInvoices) {
      this.desktopInvoices.pending.push(invoice);
      // assign new object to force change detection
      this.desktopInvoices = Object.assign({}, this.desktopInvoices);
    } else {
      this.desktopInvoices = {
        pending: [invoice],
        complete: [],
        expired: [],
      };
    }

    this.createInvoiceDialog.nativeElement.close();
  }

  browseToInvoice(invoiceId: string, tenantId: string) {
    window.location.href =
      '/admin/tenant/' + tenantId + '/invoice/' + invoiceId;
  }

  sendInvitation() {
    if (!this.invitations || !this.tenant) {
      this.errorSendingInvitation = 'Error sending invitation';
      return;
    }

    const request: UserInvitationRequest = {
      email: this.invitationEmail,
      starting_role_id: this.selectedRoleId,
      tenant_id: this.tenant.id,
      first_user: false,
    };

    this.successSendingInvitation = false;
    this.errorSendingInvitation = '';
    this.sendingInvitation = true;
    this.invitationsService.createInvitationForTenant(request).subscribe({
      next: (result) => {
        this.inviteUserModal.nativeElement.close();
        this.sendingInvitation = false;
        if (!this.invitations) {
          this.errorSendingInvitation = 'Error sending invitation';
          return;
        }
        this.successSendingInvitation = true;
        this.invitationEmail = '';
        this.selectedRoleId = '';
        this.loggerService.log('invitation created: ', result);
        this.invitations.pending.push(result.user_invitation);
      },
      error: (err) => {
        this.inviteUserModal.nativeElement.close();
        this.sendingInvitation = false;
        if (err.error?.error === 'email already in use') {
          this.errorSendingInvitation = 'Email already in use';
        } else {
          this.errorSendingInvitation = 'Error creating invitation';
        }
        this.loggerService.error(err);
      },
    });
  }

  canEditUser(userToEdit: UserWithPermissions) {
    if (!this.authService.currentUser) return false;

    return this.usersService.canEditUser(
      this.authService.currentUser,
      userToEdit
    );
  }
}
