@if (!showSMSInput && !showTOTPInput) {
  <div class="flex flex-col">
    <h1 class="mb-2 text-5xl font-semibold leading-normal">
      Choose 2FA Method
    </h1>
    @if (enrolled2FAOptions.includes('totp')) {
      <div class="mt-6 flex justify-center rounded-md border p-2">
        <div class="form-control">
          <label class="label cursor-pointer">
            <input
              class="radio mr-4"
              type="radio"
              name="2fa-option"
              [checked]="selected2FAOption === 'totp'"
              (change)="selected2FAOption = 'totp'"
            />
            <span class="label-text">Authenticator App</span>
          </label>
        </div>
      </div>
    }
    @if (enrolled2FAOptions.includes('phone')) {
      <div class="mt-6 flex justify-center rounded-md border p-2">
        <div class="form-control">
          <label class="label cursor-pointer">
            <input
              class="radio mr-4"
              type="radio"
              name="2fa-option"
              [checked]="selected2FAOption === 'phone'"
              (change)="selected2FAOption = 'phone'"
            />
            <span class="label-text">Text Message (SMS)</span>
          </label>
        </div>
      </div>
    }
    <hr class="my-8" />
    <div>
      @if (selected2FAOption === 'totp') {
        <p class="min-h-12">
          You will be prompted to enter a 6-digit code from your authenticator
          app.
        </p>
      }
      @if (selected2FAOption === 'phone') {
        <p class="min-h-12">
          You will receive a 6-digit code by text message. Text or data rates
          may apply.
        </p>
      }
    </div>
    <button class="btn btn-primary my-6 w-full" (click)="display2FAInput()">
      Next
    </button>
    <button class="btn btn-secondary w-full" (click)="back.emit()">Back</button>
  </div>
} @else if (showTOTPInput) {
  <h1 class="mb-8 text-center text-3xl font-semibold leading-normal">
    Verify with Authenticator App
  </h1>
  <div class="text-center">
    <p>Enter code from authenticator app</p>
    <input
      class="input input-bordered my-6"
      type="text"
      [(ngModel)]="totpCode"
      (keydown.enter)="verifyTOTPForSignIn()"
      placeholder="Enter 2FA Code"
    />
    <button class="btn btn-primary my-6 w-full" (click)="verifyTOTPForSignIn()">
      Verify
    </button>
    <button class="btn btn-secondary w-full" (click)="showTOTPInput = false">
      Back
    </button>
    @if (verifying) {
      <div class="loading loading-spinner mt-4"></div>
    }
  </div>
} @else if (showSMSInput) {
  <h1 class="mb-8 text-center text-3xl font-semibold leading-normal">
    Verify with Text Message
  </h1>
  <div class="text-center">
    @if (!smsSent) {
      <div>
        <p class="mb-6">
          Click below to send a Text Message to {{ phoneHint?.phoneNumber }}
        </p>
        <div>
          <button
            class="btn btn-primary mb-6 w-full"
            id="send-sms-2fa-button"
            (click)="sendSMSForSignIn()"
          >
            Send Text Message
          </button>
          <button
            class="btn btn-secondary w-full"
            (click)="showSMSInput = false"
          >
            Back
          </button>
        </div>
        @if (sendingSMS) {
          <div class="loading loading-spinner mt-4"></div>
        }
      </div>
    } @else {
      <div>
        <!-- <p class="mb-6 text-success">Text Message sent</p> -->
        <p>Enter code from Text Message</p>
        <input
          class="input input-bordered my-6"
          type="text"
          [(ngModel)]="smsCode"
          (keydown.enter)="verifySMSForSignIn()"
          placeholder="Enter SMS Code"
        />
        <button
          class="btn btn-primary mb-6 w-full"
          (click)="verifySMSForSignIn()"
        >
          Verify
        </button>
        <button
          class="btn btn-secondary w-full"
          (click)="showSMSInput = false; smsSent = false"
        >
          Back
        </button>
        @if (verifying) {
          <div class="loading loading-spinner mt-4"></div>
        }
      </div>
    }
  </div>
}

@if (error) {
  <p class="mt-8 text-center text-error">{{ error }}</p>
}
