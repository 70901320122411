import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { LicenseDetailsResolverResponse } from 'src/app/router/resolvers/desktop-license-details';
import {
  DesktopLicenseWithProduct,
  LicensesService,
  UpdateDesktopLicenseRequest,
} from 'src/app/services/licenses.service';
import { Tenant } from 'src/app/services/tenants.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-edit-license',
  templateUrl: './edit-license.component.html',
  styleUrls: ['./edit-license.component.scss'],
})
export class EditLicenseComponent {
  updates!: UpdateDesktopLicenseRequest;
  originalLicense?: DesktopLicenseWithProduct;
  tenant?: Tenant;

  inputStartDate? = '';
  inputExpirationDate? = '';

  errorFetchingLicense = '';

  updating = false;
  errorUpdating = '';
  updateSuccess = false;

  constructor(
    public location: Location,
    private route: ActivatedRoute,
    private licensesService: LicensesService,
    private loggerService: LoggerService
  ) {
    const data = this.route.snapshot.data[
      'license'
    ] as LicenseDetailsResolverResponse;

    if (data?.error) {
      this.loggerService.error(new Error(data.error));
      this.errorFetchingLicense = data.error;
      return;
    } else if (!data.desktop_license || !data.tenant) {
      this.loggerService.error(new Error('No license or tenant data'));
      this.errorFetchingLicense = 'Error fetching license and tenant data';
      return;
    }

    this.originalLicense = data.desktop_license;
    this.tenant = data.tenant;
    this.initializeUpdates(this.originalLicense);
  }

  initializeUpdates(license: DesktopLicenseWithProduct) {
    this.updates = {
      active: license.active,
      expiration: license.expiration ? new Date(license.expiration) : undefined,
      start_date: license.start_date ? new Date(license.start_date) : undefined,
      transfers_left: license.transfers_left,
      will_renew: license.will_renew,
    };

    this.inputStartDate = license.start_date?.slice(0, 10);
    this.inputExpirationDate = license.expiration?.slice(0, 10);
  }

  get updateChanges() {
    if (!this.originalLicense) {
      return null;
    }
    const changes: UpdateDesktopLicenseRequest = {};

    if (this.updates.active !== this.originalLicense.active) {
      changes.active = this.updates.active;
    }
    if (
      this.inputExpirationDate !== this.originalLicense.expiration?.slice(0, 10)
    ) {
      changes.expiration = this.inputExpirationDate
        ? new Date(this.inputExpirationDate)
        : undefined;
    }
    if (this.inputStartDate !== this.originalLicense.start_date?.slice(0, 10)) {
      changes.start_date = this.inputStartDate
        ? new Date(this.inputStartDate)
        : undefined;
    }
    if (this.updates.transfers_left !== this.originalLicense.transfers_left) {
      changes.transfers_left = this.updates.transfers_left;
    }
    if (this.updates.will_renew !== this.originalLicense.will_renew) {
      changes.will_renew = this.updates.will_renew;
    }

    if (Object.keys(changes).length === 0) {
      return null;
    }
    return changes;
  }

  validateInputs() {
    if (!this.inputStartDate) {
      this.errorUpdating = 'Please enter a start date';
      return false;
    }

    if (!this.inputExpirationDate) {
      this.errorUpdating = 'Please enter an expiration date';
      return false;
    }

    const startDate = new Date(this.inputStartDate);
    const expirationDate = new Date(this.inputExpirationDate);
    if (startDate > expirationDate) {
      this.errorUpdating = 'Start date must be before expiration date';
      return false;
    }

    if (expirationDate < new Date() && this.updates.active) {
      this.errorUpdating = 'Expiration date must be in the future when active';
      return false;
    }

    return true;
  }

  async updateLicense() {
    if (!this.originalLicense) {
      this.loggerService.error(new Error('No license to update'));
      this.errorUpdating = 'No license to update';
      return;
    }

    this.updating = true;
    this.errorUpdating = '';
    this.updateSuccess = false;

    const updates = this.updateChanges;

    if (!updates) {
      this.updating = false;
      this.errorUpdating = 'No changes to update';
      return;
    }

    if (!this.validateInputs()) {
      this.updating = false;
      return;
    }

    this.licensesService
      .updateLicense(this.originalLicense.id, updates)
      .subscribe({
        next: (res) => {
          this.loggerService.log('License updated: ', res);

          if (this.originalLicense) {
            // update all possibly changed values
            this.originalLicense.active = res.desktop_license.active;
            this.originalLicense.will_renew = res.desktop_license.will_renew;
            this.originalLicense.expiration = res.desktop_license.expiration;
            this.originalLicense.start_date = res.desktop_license.start_date;
            this.originalLicense.transfers_left =
              res.desktop_license.transfers_left;
          }
          this.updating = false;
          this.errorUpdating = '';
          this.updateSuccess = true;
          // this.location.back();
        },
        error: (error) => {
          this.loggerService.error(new Error(error));
          this.updating = false;
          this.errorUpdating = error;
          this.updateSuccess = false;
        },
      });
  }

  goBack() {
    this.location.back();
  }

  cancelChanges() {
    this.location.back();
  }
}
