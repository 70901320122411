<div class="container p-12">
  @if (fetchingTenant) {
    <div class="flex justify-center">
      <div class="loading loading-spinner loading-lg"></div>
    </div>
  } @else if (tenantFetchError || !tenant) {
    <div class="mt-4 text-center text-error">
      <p>{{ tenantFetchError ? tenantFetchError : 'Tenant not found' }}</p>
    </div>
  } @else if (tenant) {
    <app-tenant-info-form [tenant]="tenant"></app-tenant-info-form>
  }
</div>
