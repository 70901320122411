<article class="prose m-auto my-16 px-4 md:prose-lg lg:prose-xl">
  <h1>Privacy Policy</h1>
  <p>
    All information contained in the Portal, including customer account details
    and financial data, is confidential. Users are prohibited from sharing this
    information with unauthorized parties. Privacy policy information can be
    found at:
    <a target="_blank" href="https://www.accuzip.com/privacy/"
      >https://www.accuzip.com/privacy/</a
    >
  </p>
</article>
