import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  DesktopPaymentDetails,
  DesktopPaymentsService,
} from 'src/app/services/desktop-payments.service';
import {
  GroupedLicenseInvoiceDetails,
  GroupedLicenses,
  InvoiceDetails,
  InvoicesService,
} from 'src/app/services/invoices.service';
import { DesktopLicenseWithProduct } from 'src/app/services/licenses.service';
import { LoggerService } from 'src/app/services/logger.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrl: './receipt.component.scss',
})
export class ReceiptComponent {
  invoiceId?: string;
  paymentDetails?: DesktopPaymentDetails;
  invoice?: InvoiceDetails;

  loadingInvoice = false;
  loadingPaymentDetails = false;
  errorFetchingInvoice = '';
  errorFetchingPaymentDetails = '';

  groupedLicenses: GroupedLicenseInvoiceDetails[] = [];
  groupedNotRenewingLicenses: GroupedLicenses[] = [];

  constructor(
    private desktopPaymentsService: DesktopPaymentsService,
    private invoiceService: InvoicesService,
    private loggerService: LoggerService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    const params = this.route.snapshot.params;
    this.invoiceId = params['id'];
    if (!this.invoiceId) {
      this.errorFetchingInvoice = 'Error fetching invoice. Parameter missing.';
      this.loggerService.error(new Error('Invoice ID is not defined'));
      return;
    }
    this.loadInvoice(this.invoiceId);
    this.loadPaymentDetails(this.invoiceId);
  }

  loadInvoice(invoiceId: string) {
    this.loadingInvoice = true;

    this.invoiceService.getInvoiceDetails(invoiceId).subscribe({
      next: (res) => {
        this.loadingInvoice = false;
        this.loggerService.log('Invoice details: ', res);
        this.invoice = res.invoice_details;

        this.groupedLicenses = this.invoiceService.groupLicenseDetailsByProduct(
          this.invoice.license_details
        );
        this.groupedNotRenewingLicenses =
          this.invoiceService.groupLicensesByProduct(
            this.invoice.not_renewing_licenses
          );
      },
      error: (err) => {
        this.loadingInvoice = false;
        this.loggerService.error(err);
        this.errorFetchingInvoice = err;
      },
    });
  }

  loadPaymentDetails(invoiceId: string) {
    this.desktopPaymentsService.getDesktopPaymentDetails(invoiceId).subscribe({
      next: (res) => {
        this.loggerService.log('Payment details: ', res.payment_details);
        this.paymentDetails = res.payment_details;
      },
      error: (err) => {
        this.loggerService.error(err);
        this.errorFetchingPaymentDetails = err;
      },
    });
  }

  getDuration(months: number) {
    if (months % 12 === 0) {
      return months === 12 ? '1 year' : `${months / 12} years`;
    } else {
      return months === 1 ? '1 month' : `${months} months`;
    }
  }

  getPrice(license: DesktopLicenseWithProduct) {
    return this.invoiceService.getLicensePrice(license);
  }
}
