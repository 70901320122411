<article class="prose m-auto my-16 px-4 md:prose-lg lg:prose-xl">
  <h1>Terms and Conditions</h1>
  <ol>
    <li>Acceptance of Terms</li>
    <p>
      By accessing and using the AccuZIP Customer Portal (“Portal”), you
      (“User”) agree to comply with these Terms and Conditions. If you do not
      agree to these Terms, please do not use the Portal.
    </p>
    <li>Account Registration and Security</li>
    <ul>
      <li>
        Eligibility: Only authorized users with an active account are permitted
        to access the Portal.
      </li>
      <li>
        Account Security: Users are responsible for maintaining the
        confidentiality of their account credentials. If you suspect
        unauthorized use of your account, please notify us immediately.
      </li>
      <li>
        Accurate Information: Users must provide accurate and complete
        information during account registration and promptly update any
        information changes.
      </li>
    </ul>
    <li>Use of the Portal</li>
    <ul>
      <li>
        Permitted Use: The Portal is provided solely for the purpose of managing
        your account and related services with AccuZIP, Inc.. Unauthorized use,
        such as copying, modifying, distributing, or accessing the Portal for
        purposes other than its intended use, is prohibited.
      </li>
      <li>Prohibited Activities: Users agree not to:</li>
      <ul>
        <li>Engage in unauthorized access to other accounts.</li>
        <li>
          Introduce malicious software or interfere with Portal functionality.
        </li>
        <li>
          Use the Portal in any way that violates applicable laws or
          regulations.
        </li>
      </ul>
    </ul>
    <li>Confidentiality and Data Protection</li>
    <p>
      All information contained in the Portal, including customer account
      details and financial data, is confidential. Users are prohibited from
      sharing this information with unauthorized parties. Privacy policy
      information can be found at:
      <a target="_blank" href="https://www.accuzip.com/privacy/"
        >https://www.accuzip.com/privacy/</a
      >
    </p>
    <li>Payment Information</li>
    <p>
      We do not store any credit card information within the AccuZIP Customer
      Portal. Instead, we utilize secure third-party payment gateways, such as
      Authorize.net and Stripe, which are responsible for the storage and
      security of credit card information. All payment data transmitted through
      our Portal is protected using industry-standard encryption and secure data
      transmission. Users agree to maintain current and accurate payment
      information with these service providers.
    </p>
    <li>Limitation of Liability</li>
    <p>
      AccuZIP, Inc. is not liable for any damages, losses, or liabilities
      resulting from unauthorized access, security breaches, or the misuse of
      the Portal, except as required by applicable law.
    </p>
    <li>Modifications to the Terms</li>
    <p>
      AccuZIP, Inc. reserves the right to modify these Terms and Conditions at
      any time. Any changes will be posted on this page, and your continued use
      of the Portal constitutes acceptance of the modified Terms.
    </p>
    <li>Contact Information</li>
    <p>
      For questions about these Terms, please contact us at
      <a href="mailto:support@accuzip.com">support&#64;accuzip.com</a>
    </p>
  </ol>
</article>
