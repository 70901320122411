import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './router/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TestComponent } from './components/test/test.component';
import { AdminPageComponent } from './components/admin-page/admin-page.component';
import { CreateTenantComponent } from './components/admin-page/components/create-tenant/create-tenant.component';
import { TenantsListComponent } from './components/admin-page/components/tenants-list/tenants-list.component';
import { LicensesPageComponent } from './components/licenses-page/licenses-page.component';
import { ProductListComponent } from './components/admin-page/components/product-list/product-list.component';
import { ProductDetailsComponent } from './components/admin-page/components/product-details/product-details.component';
import { TenantDetailsComponent } from './components/admin-page/components/tenant-details/tenant-details.component';
import { HistoryComponent } from './components/history/history.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { TenantInfoFormComponent } from './components/tenant-info-form/tenant-info-form.component';
import { TenantUsersComponent } from './components/tenant-users/tenant-users.component';
import { TenantInfoSetupFormComponent } from './components/tenant-info-setup-form/tenant-info-setup-form.component';
import { InvoicePageComponent } from './components/invoice-page/invoice-page.component';
import { ShepherdComponent } from './components/shepherd/shepherd.component';
import { SavedPaymentMethodsComponent } from './components/saved-payment-methods/saved-payment-methods.component';
import { ToastTimerComponent } from './components/toast-timer/toast-timer.component';
import { KillSwitchComponent } from './components/admin-page/components/kill-switch/kill-switch.component';
import { EditInvoicePageComponent } from './components/edit-invoice-page/edit-invoice-page.component';
import { EditProductComponent } from './components/admin-page/components/edit-product/edit-product.component';
import { EditProductVariantComponent } from './components/admin-page/components/edit-product-variant/edit-product-variant.component';
import { AddProductComponent } from './components/admin-page/components/add-product/add-product.component';
import { AddProductVariantComponent } from './components/admin-page/components/add-product-variant/add-product-variant.component';
import { InvoicesPageComponent } from './components/invoices-page/invoices-page.component';
import { EditLicenseComponent } from './components/admin-page/components/edit-license/edit-license.component';
import { TwoFactorEnrollmentComponent } from './components/login/components/two-factor-enrollment/two-factor-enrollment.component';
import { TwoFactorLoginComponent } from './components/login/components/two-factor-login/two-factor-login.component';
import { GraphicSidePanelComponent } from './components/graphic-side-panel/graphic-side-panel.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { QrCodeIconComponent } from './components/icons/qr-code-icon/qr-code-icon.component';
import { MobilePhoneIconComponent } from './components/icons/mobile-phone-icon/mobile-phone-icon.component';
import { TrashIconComponent } from './components/icons/trash-icon/trash-icon.component';
import { TotpEnrollmentComponent } from './components/login/components/totp-enrollment/totp-enrollment.component';
import { SmsEnrollmentComponent } from './components/login/components/sms-enrollment/sms-enrollment.component';
import { ReauthenticateComponent } from './components/login/components/reauthenticate/reauthenticate.component';
import { UserSettingIconComponent } from './components/icons/user-setting-icon/user-setting-icon.component';
import { ClipboardIconComponent } from './components/icons/clipboard-icon/clipboard-icon.component';
import { PresentIconComponent } from './components/icons/present-icon/present-icon.component';
import { PlusIconComponent } from './components/icons/plus-icon/plus-icon.component';
import { EditIconComponent } from './components/icons/edit-icon/edit-icon.component';
import { FileInvoiceIconComponent } from './components/icons/file-invoice-icon/file-invoice-icon.component';
import { UsersIconComponent } from './components/icons/users-icon/users-icon.component';
import { CreditCardIconComponent } from './components/icons/credit-card-icon/credit-card-icon.component';
import { BellIconComponent } from './components/icons/bell-icon/bell-icon.component';
import { SunIconComponent } from './components/icons/sun-icon/sun-icon.component';
import { MoonIconComponent } from './components/icons/moon-icon/moon-icon.component';
import { MobileNavComponent } from './components/mobile-nav/mobile-nav.component';
import { BuildingIconComponent } from './components/icons/building-icon/building-icon.component';
import { UserIconComponent } from './components/icons/user-icon/user-icon.component';
import { GoogleIconComponent } from './components/icons/google-icon/google-icon.component';
import { FileCopyIconComponent } from './components/icons/file-copy-icon/file-copy-icon.component';
import { ManageAuthComponent } from './components/manage-auth/manage-auth.component';
import { HomeIconComponent } from './components/icons/home-icon/home-icon.component';
import { RightArrowIconComponent } from './components/icons/right-arrow-icon/right-arrow-icon.component';
import { TenantInvoiceComponent } from './components/admin-page/components/tenant-invoice/tenant-invoice.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { FranchisesListComponent } from './components/admin-page/components/franchises-list/franchises-list.component';
import { FranchiseDetailsComponent } from './components/admin-page/components/franchise-details/franchise-details.component';
import { ChevronDownIconComponent } from './components/icons/chevron-down-icon/chevron-down-icon.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { UndoIconComponent } from './components/icons/undo-icon/undo-icon.component';
import { CloseIconComponent } from './components/icons/close-icon/close-icon.component';
import { AddLicenseComponent } from './components/admin-page/components/tenant-details/add-license/add-license.component';
import { LeftArrowIconComponent } from './components/icons/left-arrow-icon/left-arrow-icon.component';
import { TenantInfoPageComponent } from './components/tenant-info-page/tenant-info-page.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { TenantAdminEditComponent } from './components/admin-page/components/tenant-admin-edit/tenant-admin-edit.component';
import { CreateInvoiceComponent } from './components/admin-page/components/tenant-details/create-invoice/create-invoice.component';

@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    TopNavComponent,
    LoginComponent,
    DashboardComponent,
    TestComponent,
    AdminPageComponent,
    CreateTenantComponent,
    TenantsListComponent,
    LicensesPageComponent,
    ProductListComponent,
    ProductDetailsComponent,
    TenantDetailsComponent,
    HistoryComponent,
    SignUpComponent,
    TenantInfoFormComponent,
    TenantUsersComponent,
    TenantInfoSetupFormComponent,
    InvoicePageComponent,
    ShepherdComponent,
    SavedPaymentMethodsComponent,
    ToastTimerComponent,
    KillSwitchComponent,
    EditInvoicePageComponent,
    EditProductComponent,
    EditProductVariantComponent,
    AddProductComponent,
    AddProductVariantComponent,
    InvoicesPageComponent,
    EditLicenseComponent,
    TwoFactorEnrollmentComponent,
    TwoFactorLoginComponent,
    GraphicSidePanelComponent,
    UserSettingsComponent,
    QrCodeIconComponent,
    MobilePhoneIconComponent,
    TrashIconComponent,
    TotpEnrollmentComponent,
    SmsEnrollmentComponent,
    ReauthenticateComponent,
    UserSettingIconComponent,
    ClipboardIconComponent,
    PresentIconComponent,
    PlusIconComponent,
    EditIconComponent,
    FileInvoiceIconComponent,
    UsersIconComponent,
    CreditCardIconComponent,
    BellIconComponent,
    SunIconComponent,
    MoonIconComponent,
    MobileNavComponent,
    BuildingIconComponent,
    UserIconComponent,
    GoogleIconComponent,
    FileCopyIconComponent,
    ManageAuthComponent,
    HomeIconComponent,
    RightArrowIconComponent,
    TenantInvoiceComponent,
    InvoiceComponent,
    ReceiptComponent,
    FranchisesListComponent,
    FranchiseDetailsComponent,
    ChevronDownIconComponent,
    EditUserComponent,
    UndoIconComponent,
    CloseIconComponent,
    AddLicenseComponent,
    LeftArrowIconComponent,
    TenantInfoPageComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    TenantAdminEditComponent,
    CreateInvoiceComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
