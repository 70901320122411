import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TenantResolverResponse } from 'src/app/router/resolvers/current-tenant-details';
import { LoggerService } from 'src/app/services/logger.service';
import { Tenant } from 'src/app/services/tenants.service';

@Component({
  selector: 'app-tenant-info-page',
  templateUrl: './tenant-info-page.component.html',
  styleUrl: './tenant-info-page.component.scss',
})
export class TenantInfoPageComponent {
  tenant?: Tenant;
  tenantFetchError?: string;

  constructor(
    private loggerService: LoggerService,
    private route: ActivatedRoute
  ) {
    const tenantInfo = this.route.snapshot.data[
      'tenant'
    ] as TenantResolverResponse;
    this.loggerService.log('tenantInfo: ', tenantInfo);

    if (!tenantInfo.tenant) {
      this.loggerService.error(
        new Error('Error getting tenant info: no tenant info found')
      );
      this.tenantFetchError = 'Error getting tenant info';
      return;
    } else if (tenantInfo.error) {
      this.loggerService.error(new Error(tenantInfo.error));
      this.tenantFetchError = tenantInfo.error;
      return;
    }

    this.tenant = tenantInfo.tenant;
  }
}
