import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './firebase.service';

export type Product = {
  id: string;
  active: boolean;
  code: string;
  variance_code: string;
  title: string;
  description: string;
  standalone: boolean;
  type: ProductType;
  is_service: boolean;
  expiration_type: ExpirationType;
  requires_parent: boolean;
  created_at: string;
  updated_at: string;
};

export type ProductType =
  | 'api'
  | 'cass'
  | 'desktop'
  | 'physical'
  | 'service'
  | 'sorter'
  | 'toolkit'
  | 'tracking';

export type ExpirationType =
  | 'desktop'
  | 'annual'
  | 'force_extension'
  | 'physical'
  | 'transactional';

export type ProductVariant = {
  id: string;
  product_id: string;
  franchise_id?: string;
  description: string;
  active: boolean;
  is_default: boolean;
  variance_code: string;
  delivery_method: string;
  duration_months: number;
  price_lock_level: number;
  price_key: string;
  site_price_key: string;
  renewal_price_key: string;
  site_renewal_price_key: string;
  tax_code: string;
  site_tax_code: string;
  renewal_tax_code: string;
  site_renewal_tax_code: string;
  price: number;
  site_price: number;
  renewal_price: number;
  site_renewal_price: number;
  created_at: string;
  updated_at: string;
};

export type ProductWithVariants = {
  product: Product;
  variants: ProductVariant[];
};

export type ProductResponse = {
  product: Product;
};

export type ProductsResponse = {
  products: Product[];
};

export type ProductDetailsResponse = {
  product_with_variants: ProductWithVariants;
};

export type ProductVariantResponse = {
  product_variant: ProductVariant;
};

export type ProductVariantsResponse = {
  product_variants: ProductVariant[];
};

export type NewProductRequest = {
  active: boolean;
  code: string;
  variance_code: string;
  description: string;
  standalone: boolean;
  type: ProductType;
  is_service: boolean;
};

export type UpdateProductRequest = {
  active?: boolean;
  code?: string;
  variance_code?: string;
  title?: string;
  description?: string;
  standalone?: boolean;
  type?: ProductType;
  is_service?: boolean;
};

export type NewProductVariantRequest = {
  product_id: string;
  franchise_id?: string;
  description: string;
  active: boolean;
  is_default: boolean;
  variance_code: string;
  delivery_method: string;
  duration_months: number;
  price_lock_level: number;
  price_key: string;
  site_price_key?: string;
  renewal_price_key?: string;
  site_renewal_price_key?: string;
  tax_code: string;
  site_tax_code?: string;
  renewal_tax_code?: string;
  site_renewal_tax_code?: string;
  price: number;
  site_price?: number;
  renewal_price?: number;
  site_renewal_price?: number;
};

export type UpdateProductVariantRequest = {
  product_id?: string;
  franchise_id?: string;
  description?: string;
  active?: boolean;
  is_default?: boolean;
  variance_code?: string;
  delivery_method?: string;
  duration_months?: number;
  price_lock_level?: number;
  price_key?: string;
  site_price_key?: string;
  renewal_price_key?: string;
  site_renewal_price_key?: string;
  tax_code?: string;
  site_tax_code?: string;
  renewal_tax_code?: string;
  site_renewal_tax_code?: string;
  price?: number;
  site_price?: number;
  renewal_price?: number;
  site_renewal_price?: number;
};

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  getAllProductsUrl = environment.serverUrl + '/products/get-all';
  getProductUrl = environment.serverUrl + '/products/get';
  productDetailsUrl = environment.serverUrl + '/products/get-details';
  createProductUrl = environment.serverUrl + '/products/create';
  getProductVariantUrl = environment.serverUrl + '/products/get-variant';
  createProductVariantUrl = environment.serverUrl + '/products/create-variant';
  durationOptionsUrl =
    environment.serverUrl + '/products/get-variant-durations';
  updateProductUrl = environment.serverUrl + '/products/update';
  updateProductVariantUrl = environment.serverUrl + '/products/update-variant';

  productTypes: ProductType[] = [
    'api',
    'cass',
    'desktop',
    'physical',
    'service',
    'sorter',
    'toolkit',
    'tracking',
  ];

  deliveryMethods = ['DNL', 'XXX'];

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {}

  getAllProducts() {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<ProductsResponse>(this.getAllProductsUrl, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  getProduct(productId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<ProductResponse>(
          this.getProductUrl + '/' + productId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  getProductDetails(productId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<ProductDetailsResponse>(
          this.productDetailsUrl + '/' + productId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  createProduct(product: NewProductRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<ProductResponse>(this.createProductUrl, product, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  getProductVariant(productVariantId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<ProductVariantResponse>(
          this.getProductVariantUrl + '/' + productVariantId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  createProductVariant(productVariant: NewProductVariantRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<ProductVariantResponse>(
          this.createProductVariantUrl,
          productVariant,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  getDurationOptions(productVariant: ProductVariant) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<ProductVariantsResponse>(
          this.durationOptionsUrl + '/' + productVariant.id,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  updateProduct(productId: string, productUpdate: UpdateProductRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<ProductResponse>(
          this.updateProductUrl + '/' + productId,
          productUpdate,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  updateProductVariant(
    productVariantId: string,
    productVariantUpdate: UpdateProductVariantRequest
  ) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<ProductVariantResponse>(
          this.updateProductVariantUrl + '/' + productVariantId,
          productVariantUpdate,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }
}
