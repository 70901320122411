import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CurrentPaymentMethodsResolverResponse } from 'src/app/router/resolvers/current-tenant-payment-methods';
import { AcceptJSService } from 'src/app/services/accept-js.service';
import { AuthService } from 'src/app/services/auth.service';
import { CassResponse, CassService } from 'src/app/services/cass.service';
import { LoggerService } from 'src/app/services/logger.service';
import {
  AuthorizeNetSavedPaymentMethod,
  PaymentsService,
} from 'src/app/services/payments.service';
import { environment } from 'src/environments/environment';

type Input = {
  value: string;
  error: string;
};

@Component({
  selector: 'app-saved-payment-methods',
  templateUrl: './saved-payment-methods.component.html',
  styleUrls: ['./saved-payment-methods.component.scss'],
})
export class SavedPaymentMethodsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('acceptPaymentButton')
  acceptPaymentButton!: ElementRef<HTMLButtonElement>;
  @ViewChild('addressCorrectionModal')
  addressCorrectionModal!: ElementRef<HTMLDialogElement>;
  @ViewChild('removeMethodModal')
  removeMethodModal!: ElementRef<HTMLDialogElement>;

  savedPaymentMethods?: AuthorizeNetSavedPaymentMethod[];
  errorFetchingPaymentMethods = '';

  loading = false;

  inputs: { [prop: string]: Input } = {
    first: { value: '', error: '' },
    last: { value: '', error: '' },
    company: { value: '', error: '' },
    address: { value: '', error: '' },
    city: { value: '', error: '' },
    state: { value: '', error: '' },
    zip: { value: '', error: '' },
  };
  isDefault = false;
  savedCardResponse: any;

  cassResponse?: CassResponse = undefined;

  creatingSavedMethod = false;
  addSavedMethodError = '';

  methodToRemove?: AuthorizeNetSavedPaymentMethod;
  removingSavedMethod = false;

  // TODO: Replace autopay variable assignment with service to call current user
  // setting
  autopay = true;
  showAutopayWarning = false;

  constructor(
    private acceptJSService: AcceptJSService,
    private authService: AuthService,
    private cassService: CassService,
    private loggerService: LoggerService,
    private route: ActivatedRoute,
    private paymentsService: PaymentsService
  ) {}

  get authNetAPILoginID() {
    return environment.authorizeNet.apiLoginID;
  }

  get authNetClientKey() {
    return environment.authorizeNet.clientKey;
  }

  get canManagePaymentMethods() {
    return this.authService.currentUser?.permissions.find(
      (p) =>
        p.role.name === 'super_admin' ||
        p.role.name === 'internal_admin' ||
        p.role.name === 'admin' ||
        p.role.name === 'owner'
    );
  }

  ngOnInit() {
    window.savedCardResponseHandler = this.savedCardResponseHandler.bind(this);

    const data = this.route.snapshot.data[
      'paymentMethods'
    ] as CurrentPaymentMethodsResolverResponse;

    if (data?.error) {
      this.loggerService.error(new Error(data.error));
      this.errorFetchingPaymentMethods = data.error;
      return;
    } else if (!data.payment_profiles) {
      this.loggerService.log('No payment methods found');
      this.errorFetchingPaymentMethods = 'No payment methods found';
      return;
    }

    this.loggerService.log('route: ', this.route.snapshot.data);
    this.savedPaymentMethods = this.route.snapshot.data['paymentMethods']
      .payment_profiles as AuthorizeNetSavedPaymentMethod[];
  }

  ngAfterViewInit() {
    this.acceptJSService.injectScript();
  }

  ngOnDestroy(): void {
    this.acceptJSService.removeScript();
  }

  get formattedInputAddress() {
    let str = '';
    if (this.inputs['company'].value) {
      str += `${this.inputs['company'].value}<br>`;
    }
    str +=
      `${this.inputs['address'].value}<br>${this.inputs['city'].value}, ` +
      `${this.inputs['state'].value} ${this.inputs['zip'].value}`;
    return str;
  }

  get formattedCassAddress() {
    if (!this.cassResponse) {
      return '';
    }

    let str = '';
    if (this.cassResponse.company) {
      str += `${this.cassResponse.company}<br>`;
    }
    str +=
      `${this.cassResponse.address}<br>${this.cassResponse.city}, ` +
      `${this.cassResponse.state} ${this.cassResponse.zip}`;
    return str;
  }

  get addressValidated() {
    return (
      this.cassResponse !== undefined &&
      this.cassResponse.dpva !== undefined &&
      this.cassResponse.dpva !== '' &&
      this.cassResponse.dpva !== 'N'
    );
  }

  formattedAddress(method: AuthorizeNetSavedPaymentMethod) {
    let str = '';
    if (method.company) {
      str += `${method.company}<br>`;
    } else {
      str += `${method.first_name} ${method.last_name}<br>`;
    }
    str += `${method.address}<br>${method.city}, ${method.state} ${method.zip_code}`;
    return str;
  }

  validateInputAddress() {
    if (!this.validateInputs()) {
      return;
    }

    this.cassAddress().subscribe({
      next: (res) => {
        this.cassResponse = res;
        this.promptForAddressCorrection();
        this.loggerService.log('Address validation complete:', res);
      },
      error: (err) => {
        this.promptForAddressCorrection();
        this.loggerService.error(err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  validateInputs() {
    for (const key in this.inputs) {
      this.inputs[key].error = '';
    }

    if (
      !(
        (this.inputs['first'].value.trim() &&
          this.inputs['last'].value.trim()) ||
        this.inputs['company'].value.trim()
      )
    ) {
      this.inputs['first'].error =
        'At least one of First/Last or Company is required';
      this.inputs['last'].error =
        'At least one of First/Last or Company is required';
      this.inputs['company'].error =
        'At least one of First/Last or Company is required';
    }

    if (!this.inputs['address'].value.trim()) {
      this.inputs['address'].error = 'Address is required';
    }

    if (!this.inputs['city'].value.trim()) {
      this.inputs['city'].error = 'City is required';
    }

    if (!this.inputs['state'].value.trim()) {
      this.inputs['state'].error = 'State is required';
    }

    if (!this.inputs['zip'].value.trim()) {
      this.inputs['zip'].error = 'Zip is required';
    }

    for (const key in this.inputs) {
      if (this.inputs[key].error) {
        return false;
      }
    }

    return true;
  }

  cassAddress() {
    this.loggerService.log('validating address: ', this.inputs);
    const req = {
      company: this.inputs['company'].value,
      address: this.inputs['address'].value,
      city: this.inputs['city'].value,
      state: this.inputs['state'].value,
      zip: this.inputs['zip'].value,
    };

    this.loading = true;
    this.cassResponse = undefined;

    return this.cassService.validateAddress(req);
  }

  promptForAddressCorrection() {
    this.addressCorrectionModal.nativeElement.showModal();
  }

  hideAddressCorrectionModal() {
    this.addressCorrectionModal.nativeElement.close();
  }

  updateAddressFromStandardized() {
    this.inputs['company'].value = this.cassResponse?.company || '';
    this.inputs['address'].value = this.cassResponse?.address || '';
    this.inputs['city'].value = this.cassResponse?.city || '';
    this.inputs['state'].value = this.cassResponse?.state || '';
    this.inputs['zip'].value = this.cassResponse?.zip || '';

    this.hideAddressCorrectionModal();
    this.promptForCardInfo();
  }

  promptForCardInfo() {
    this.acceptPaymentButton.nativeElement.click();
  }

  savedCardResponseHandler(response: any) {
    this.loggerService.log('saved card info response: ', response);
    this.savedCardResponse = JSON.stringify(response, null, 2);

    if (response.messages.resultCode === 'Ok') {
      this.creatingSavedMethod = true;
      this.loggerService.log('Card info successful');

      const tenantId = this.authService.currentUser?.tenant_id;
      if (!tenantId) {
        this.loggerService.error(new Error('No tenant ID found'));
        return;
      }

      this.paymentsService
        .createNewPaymentMethod({
          opaque_data: response.opaqueData,
          bill_to: {
            firstName: this.inputs['first'].value,
            lastName: this.inputs['last'].value,
            company: this.inputs['company'].value,
            address: this.inputs['address'].value,
            city: this.inputs['city'].value,
            state: this.inputs['state'].value,
            zip: this.inputs['zip'].value,
          },
          tenant_id: tenantId,
          is_default: this.isDefault,
        })
        .subscribe({
          next: (res) => {
            if (!this.savedPaymentMethods) {
              this.addSavedMethodError = 'Error creating payment method';
              return;
            }

            for (const key in this.inputs) {
              this.inputs[key].value = '';
              this.inputs[key].error = '';
            }
            this.loggerService.log('Payment method created:', res);
            this.savedPaymentMethods.push(res.payment_profile);
            // if returned payment method is default, update all others
            if (res.payment_profile.is_default) {
              this.savedPaymentMethods.forEach((p) => {
                p.is_default = p.id === res.payment_profile.id;
              });
            }
          },
          error: (err) => {
            this.loggerService.error(err);
            this.addSavedMethodError =
              'There was an error creating your saved payment method.';
          },
          complete: () => {
            this.creatingSavedMethod = false;
          },
        });
    } else {
      this.loggerService.error(
        new Error(JSON.stringify(response.messages, null, 2))
      );
      this.addSavedMethodError = 'There was an error capturing your card info.';
    }

    return;
  }

  setDefaultPaymentMethod(paymentMethod: AuthorizeNetSavedPaymentMethod) {
    if (!this.savedPaymentMethods) {
      this.loggerService.error(new Error('No saved payment methods set'));
      this.addSavedMethodError = 'No saved payment methods found';
      return;
    }

    this.loggerService.log('setting default payment method: ', paymentMethod);
    this.paymentsService.setDefaultPaymentMethod(paymentMethod).subscribe({
      next: (res) => {
        this.loggerService.log('Payment method set as default:', res);
        this.savedPaymentMethods!.forEach((p) => {
          p.is_default = p.id === paymentMethod.id;
        });
      },
      error: (err) => {
        this.loggerService.error(err);
      },
    });
  }

  confirmDeleteSavedMethod(paymentMethod: AuthorizeNetSavedPaymentMethod) {
    this.methodToRemove = paymentMethod;
    this.removeMethodModal.nativeElement.showModal();
  }

  hideRemoveMethodModal() {
    this.removeMethodModal.nativeElement.close();
  }

  deleteSavedPaymentMethod(paymentMethod: AuthorizeNetSavedPaymentMethod) {
    if (!this.savedPaymentMethods) {
      this.loggerService.error(new Error('No saved payment methods set'));
      this.addSavedMethodError = 'No saved payment methods found';
      return;
    }

    this.loggerService.log('deleting payment method: ', paymentMethod);
    this.removingSavedMethod = true;
    this.paymentsService.deleteSavedPaymentMethod(paymentMethod).subscribe({
      next: (res) => {
        this.removingSavedMethod = false;
        this.hideRemoveMethodModal();
        this.loggerService.log('Payment method deleted:', res);
        this.savedPaymentMethods = this.savedPaymentMethods!.filter(
          (p) => p.id !== paymentMethod.id
        );
      },
      error: (err) => {
        this.removingSavedMethod = false;
        this.hideRemoveMethodModal();
        this.loggerService.error(err);
        this.addSavedMethodError =
          'There was an error removing the saved payment method.';
      },
    });
  }

  toggleAutopay(autopay: boolean, ev: Event) {
    if (this.autopay) {
      ev.preventDefault();
      this.showAutopayWarning = true;
    } else if (!autopay) {
      this.autopay = true;
      this.showAutopayWarning = false;
      this.saveAutopaySetting(this.autopay);
    }
  }

  disableAutopay() {
    this.autopay = false;
    this.showAutopayWarning = false;
    this.saveAutopaySetting(this.autopay);
  }

  saveAutopaySetting(autopay: boolean) {
    this.loggerService.log('saving - autopay setting: ', autopay);
    // TODO: Add call to update server and auth.net with autopay setting
    // This is currently non functional and just UI elements
    // Add a check to verify there is a valid default payment method before
    // allowing autopay to be enabled to avoid user confusion
  }
}
