import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  CreateInvoiceRequest,
  InvoiceDetails,
  InvoicesService,
} from 'src/app/services/invoices.service';
import { DesktopLicenseWithProduct } from 'src/app/services/licenses.service';
import { LoggerService } from 'src/app/services/logger.service';

type DisplayLicenses = {
  status: string;
  license: DesktopLicenseWithProduct;
};

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrl: './create-invoice.component.scss',
})
export class CreateInvoiceComponent implements OnInit, OnChanges {
  @Input() desktopLicenses?: {
    active: DesktopLicenseWithProduct[];
    expired: DesktopLicenseWithProduct[];
    pending: DesktopLicenseWithProduct[];
  };
  @Input() desktopInvoices?: {
    pending: InvoiceDetails[];
    complete: InvoiceDetails[];
    expired: InvoiceDetails[];
  };

  @Output() invoiceCreated = new EventEmitter<InvoiceDetails>();

  creatingInvoice = false;
  createInvoiceFilters = {
    active: true,
    pending: true,
    expired: true,
  };
  validInvoiceLicenses?: DisplayLicenses[];
  errorCreatingInvoice = '';
  licensesIdsForInvoice: string[] = [];

  constructor(
    private loggerService: LoggerService,
    private invoicesService: InvoicesService
  ) {}

  ngOnInit(): void {
    this.filterValidInvoiceLicenses();
  }

  ngOnChanges(): void {
    this.filterValidInvoiceLicenses();
  }

  filterValidInvoiceLicenses() {
    let all: DisplayLicenses[] = [];
    if (this.desktopLicenses?.active && this.createInvoiceFilters.active) {
      all.push(
        ...this.desktopLicenses.active.map((l) => ({
          status: 'Active',
          license: l,
        }))
      );
    }
    if (this.desktopLicenses?.expired && this.createInvoiceFilters.expired) {
      all.push(
        ...this.desktopLicenses.expired.map((l) => ({
          status: 'Expired',
          license: l,
        }))
      );
    }
    if (this.desktopLicenses?.pending && this.createInvoiceFilters.pending) {
      all.push(
        ...this.desktopLicenses.pending.map((l) => ({
          status: 'Pending',
          license: l,
        }))
      );
    }

    // filter out licenses already used in pending invoices
    if (this.desktopInvoices?.pending) {
      const pendingInvoices = this.desktopInvoices.pending;
      all = all.filter((license) => {
        return !pendingInvoices.find((invoice) => {
          return invoice.license_details.find(
            (l) => l.license_with_product.id === license.license.id
          );
        });
      });
    }

    this.validInvoiceLicenses = all;
  }

  handleInvoiceLicenseCheck(ev: Event) {
    const checkbox = ev.target as HTMLInputElement;
    const licenseId = checkbox.value;
    const checked = checkbox.checked;

    if (checked) {
      // add license to pending invoice creation list
      this.licensesIdsForInvoice.push(licenseId);
    } else {
      // remove license from pending invoice creation list
      this.licensesIdsForInvoice = this.licensesIdsForInvoice.filter(
        (id) => id !== licenseId
      );
    }

    this.loggerService.log(
      'licensesIdsForInvoice: ',
      this.licensesIdsForInvoice
    );
  }

  createInvoice() {
    if (this.licensesIdsForInvoice.length === 0) {
      this.errorCreatingInvoice = 'No licenses selected for invoice';
      return;
    }

    this.creatingInvoice = true;
    const request: CreateInvoiceRequest = {
      desktop_license_ids: this.licensesIdsForInvoice,
    };

    this.loggerService.debug('create invoice request: ', request);

    this.invoicesService.createInvoice(request).subscribe({
      next: (res) => {
        this.creatingInvoice = false;
        this.loggerService.log('res: ', res);
        if (res.invoice_details) {
          this.invoiceCreated.emit(res.invoice_details);
        } else {
          this.loggerService.error(
            new Error('No invoice_details returned from server')
          );
          this.errorCreatingInvoice = 'Server error creating invoice';
        }
      },
      error: (err) => {
        this.creatingInvoice = false;
        this.loggerService.error(err);

        if (err.error.error === 'invalid address') {
          // tenant does not have a valid address
          this.errorCreatingInvoice =
            'Tenant does not have a valid address. Unable to create invoice.';
        } else {
          this.errorCreatingInvoice = err.error.error;
        }
      },
    });
  }
}
