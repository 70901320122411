import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';
import { mergeMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product, ProductVariant } from './products.service';
import { Tenant } from './tenants.service';

export type DesktopLicense = {
  id: string;
  tenant_id: string;
  product_variant_id: string;
  parent_desktop_license_id?: string;
  start_date?: string;
  expiration?: string;
  transfers_left: number;
  site_license: boolean;
  renewal: boolean;
  active: boolean;
  will_renew: boolean;
  created_at: string;
};

export type DesktopLicenseWithProduct = DesktopLicense & {
  product: Product;
  product_variant: ProductVariant;
};

export type CreateDesktopLicenseRequest = {
  tenant_id: string;
  product_variant_id: string;
  start_date: Date;
  is_site_license: boolean;
  parent_desktop_license_id?: string;
};

export type LicensesResponse = {
  desktop_licenses: {
    active: DesktopLicenseWithProduct[];
    expired: DesktopLicenseWithProduct[];
    pending: DesktopLicenseWithProduct[];
  };
};

export type LicenseResponse = {
  desktop_license: DesktopLicense;
};

type LicenseWithProductResponse = {
  desktop_license: DesktopLicenseWithProduct;
};

export type LicenseDetailsResponse = {
  desktop_license: DesktopLicenseWithProduct;
  tenant: Tenant;
};

export type UpdateDesktopLicenseRequest = {
  active?: boolean;
  will_renew?: boolean;
  expiration?: Date;
  start_date?: Date;
  transfers_left?: number;
};

@Injectable({
  providedIn: 'root',
})
export class LicensesService {
  private getActiveLicensesUrl = environment.serverUrl + '/licenses/get-active';
  private getLicenseUrl = environment.serverUrl + '/licenses/get';
  private getLicenseDetailsUrl =
    environment.serverUrl + '/licenses/get-details';
  private licensesByTentantUrl = environment.serverUrl + '/licenses/for-tenant';
  private addLicenseToTenantUrl = environment.serverUrl + '/licenses/create';
  private updateWillRenewUrl =
    environment.serverUrl + '/licenses/update-will-renew';
  private updateLicenseUrl = environment.serverUrl + '/licenses/update';

  constructor(
    private http: HttpClient,
    private firebaseService: FirebaseService
  ) {}

  getActiveLicenses() {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<LicensesResponse>(this.getActiveLicensesUrl, {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        });
      })
    );
  }

  getLicense(licenseId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<LicenseResponse>(
          this.getLicenseUrl + '/' + licenseId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  getLicenseDetails(licenseId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<LicenseDetailsResponse>(
          this.getLicenseDetailsUrl + '/' + licenseId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  getLicensesForTenant(tenantId: string) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.get<LicensesResponse>(
          this.licensesByTentantUrl + '/' + tenantId,
          {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  addLicenseToTenant(createRequest: CreateDesktopLicenseRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.post<LicenseWithProductResponse>(
          this.addLicenseToTenantUrl,
          createRequest,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  updateLicenseWillRenew(license: DesktopLicense) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.put<LicenseResponse>(
          this.updateWillRenewUrl + '/' + license.id,
          { will_renew: license.will_renew },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }

  updateLicense(licenseId: string, request: UpdateDesktopLicenseRequest) {
    return this.firebaseService.idToken().pipe(
      mergeMap((token) => {
        return this.http.put<LicenseResponse>(
          this.updateLicenseUrl + '/' + licenseId,
          request,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + token,
            },
          }
        );
      })
    );
  }
}
