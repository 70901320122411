import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import {
  NavigationEnd,
  NavigationCancel,
  NavigationError,
  Router,
  NavigationStart,
} from '@angular/router';
import { FirebaseService } from './services/firebase.service';
import { ThemeService } from './services/theme.service';
import { LoggerService } from './services/logger.service';
const version = require('./version.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'customer-portal';
  private hideNav = true;
  error = '';
  appReady = false;

  // toggles loading spinner when navigating between routes
  loading = false;

  constructor(
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private loggerService: LoggerService,
    private router: Router,
    private themeService: ThemeService
  ) {
    this.loggerService.debug('app.component -> constructor');
    // always log the version number
    console.log('version: ', version);
    this.themeService.initTheme();

    this.checkAuth();
    this.toggleNavOnRouteChange();
    this.showLoadingSpinnerOnNavigation();
    // initialize theme service
  }

  get showNav() {
    return /* this.authService.signedIn && */ !this.hideNav;
  }

  // Checks if the auth service is ready. The _appReady
  checkAuth() {
    this.authService.authReady.subscribe((authReady) => {
      this.loggerService.log(
        'app.component -> checkAuth -> authReady: ',
        authReady
      );
      this.appReady = authReady;
      if (this.appReady === false) {
        // there was an error starting the auth service
        this.firebaseService.logAnalyticsEvent('app_error', {
          error: 'auth_service_error',
        });
        this.error = 'Unexpected error encountered. Please try again later.';
      }
    });
  }

  showLoadingSpinnerOnNavigation() {
    this.router.events.subscribe((routerEvent) => {
      // this.loggerService.log('router event: ', routerEvent);
      if (
        routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationCancel ||
        routerEvent instanceof NavigationError
      ) {
        this.loading = false;
      } else if (routerEvent instanceof NavigationStart) {
        this.loading = true;
      }
    });
  }

  toggleNavOnRouteChange() {
    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        if (
          routerEvent.url.startsWith('/login') ||
          routerEvent.url.startsWith('/sign-up') ||
          routerEvent.url.startsWith('/manage-auth') ||
          routerEvent.url.startsWith('/terms-and-conditions') ||
          routerEvent.url.startsWith('/privacy-policy')
        ) {
          this.hideNav = true;
        } else {
          this.hideNav = false;
        }
      }
    });
  }
}
