import { Component } from '@angular/core';

@Component({
  selector: 'app-left-arrow-icon',
  templateUrl: './left-arrow-icon.component.html',
  styleUrl: './left-arrow-icon.component.scss'
})
export class LeftArrowIconComponent {

}
